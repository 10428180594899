<template>
  <v-dialog v-model="dialogOpen" persistent max-width="1000">
    <v-card height="800">
      <v-toolbar dark color="grey darken-4">
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span>E-Mail versenden</span>
          <span v-if="emailTemplateName">{{ ' "' + emailTemplateName + '"' }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container class2="d-flex flex-column align-center" fluid>
        <div class="d-flex flex-row mb-2">
          <div class="d-flex flex-column">
            <div class="d-flex align-baseline">
              <div class="text-body-1 grey--text text--darken-3 mr-3" style="width: 90px; text-align: right">
                Absender
              </div>

              <v-btn-toggle v-model="selectedSender" mandatory dense color="primary darken-2">
                <v-btn v-for="sender in senderAddresses" :key="sender" style="text-transform: none">
                  {{ sender }}
                </v-btn>
              </v-btn-toggle>

            </div>
            <div class="d-flex mt-2">
              <div class="text-body-1 grey--text text--darken-3 mr-3" style="width: 90px; text-align: right">
                Empfänger
              </div>
              <div v-text="recipientAddress" class="text-body-1 black--text font-weight-medium"></div>
            </div>
          </div>
          <v-spacer></v-spacer>

          <div class="d-flex align-center">
            <v-btn color="green darken-2" dark :loading="sendingInProgress" @click="sendEmail">
              Senden
              <v-icon right>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="d-flex align-baseline">
          <div class="text-body-1 grey--text text--darken-3 mr-3" style="width: 90px; text-align: right">Betreff</div>
          <v-text-field v-model="subject" class="flex-grow-1" filled hide-details dense></v-text-field>
        </div>

        <quill-editor class="full-dialog-height mt-3" :options="editorOption" v-model="emailText"/>

      </v-container>

      <v-dialog v-model="illegalContentDialogOpen" max-width="500">
        <v-card>
          <v-card-title>
            <span class="">Unerlaubte Zeichen</span>
          </v-card-title>
          <v-card-text>
            <span class="black--text text-body-1">
              Der E-Mail-Inhalt enthält unerlaubte Zeichen wie $ oder ERROR. Bitte entferne diese.
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" dark text @click="illegalContentDialogOpen = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-dialog>
</template>

<script>
import mitt from "mitt";

window.mitt = window.mitt || new mitt();
export default {
  name: "SendEmailDialog",
  data: () => ({
    dialogOpen: false,
    senderAddresses: [],
    selectedSender: undefined,
    recipientAddress: undefined,
    subject: undefined,
    emailText: undefined,
    emailTemplateName: undefined,
    illegalContentDialogOpen: false,
    sendingInProgress: false,
    // static fields
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ "list": "ordered" }, { "list": "bullet" }],
          [{ "script": "sub" }, { "script": "super" }],
          [{ "header": [1, 2, 3, false] }],
          [{ "size": ["small", false, "large", "huge"] }],
          [{ "color": [] }, { "background": [] }],
          ["clean"]
        ]
      }
    }
  }),
  computed: {},
  mounted() {
    window.mitt.on("showSendEmailDialog", data => {
      this.emailTemplateName = data.emailTemplateName;
      this.senderAddresses = data.senderAddresses;
      this.recipientAddress = data.recipientAddress;
      this.subject = data.subject;
      this.emailText = data.content;
      this.dialogOpen = true;
    });
  },
  methods: {
    sendEmail() {
      if (this.emailText.includes("$") || this.emailText.includes("ERROR")) {
        this.illegalContentDialogOpen = true;
        return;
      }
      let payload = {
        subject: this.subject,
        content: this.emailText,
        recipientAddress: this.recipientAddress,
        senderAddress: this.senderAddresses[this.selectedSender],
        templateName: this.emailTemplateName
      };
      this.$store.dispatch("postRequest", ["outgoing-emails", payload, this.sendEmailCallback, this.errorCallback])
      this.sendingInProgress = true;
    },
    sendEmailCallback() {
      this.sendingInProgress = false;
      this.closeDialog();
    },
    errorCallback() {
      alert("Ein Fehler ist aufgetreten. Bitte kontaktiere Simon Kemmesies.")
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog() {
      this.dialogOpen = false;
    }
  }
};
</script>

<style>
.quill-editor.full-dialog-height {
  display: flex;
  flex-direction: column;
  height: 584px;
}

.quill-editor.full-dialog-height .ql-container {
  overflow: auto;
}
</style>