<template>
  <v-card style="background-color: #f8f8f8">
    <v-toolbar tile dark color="grey darken-4">
      <v-spacer></v-spacer>
      <v-toolbar-title>Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-container class="py-3" fluid>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <v-card max-width="500" width="500">
            <v-card-title class="d-flex justify-center" style2="background-color: #e4e4e4">
              <div class="text-h5">Gespräche</div>
            </v-card-title>
            <v-divider></v-divider>
            <div v-if="salesCalls.length === 0"
                 class="my-3 text-center text-h6 font-italic font-weight-light grey--text">
              keine Einträge
            </div>
            <v-list two-line class="pa-0">
              <v-list-item-group v-model="selectedCallIndex">
                <template v-for="(callDto, index) in salesCalls">
                  <v-list-item :key="'salesCall' + callDto['salesCall'].id">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <div class="d-flex flex-row align-baseline">
                            <v-icon v-text="getCallTypeIcon(callDto['salesCall'].type)" left small></v-icon>
                            <div v-text="getCallTypeString(callDto['salesCall'].type)"></div>
                            <v-spacer></v-spacer>
                            <v-chip v-if="!ongoingCall || callDto['salesCall'].id !== ongoingCall.id" small outlined label
                                    :color="possibleCallOutcomes[callDto['salesCall'].outcome].color">
                              <v-icon left small>{{ possibleCallOutcomes[callDto["salesCall"].outcome].icon }}
                              </v-icon>
                              {{ possibleCallOutcomes[callDto["salesCall"].outcome].label }}
                              <span v-if="callDto['salesCall'].followUpDate" class="ml-1">
                                zum
                                {{ callDto["salesCall"].followUpDate | formatDateShort }}
                              </span>
                            </v-chip>
                          </div>
                        </v-list-item-title>

                        <v-list-item-subtitle class="text--primary mt-2 mb-1">
                          <div class="d-flex flex-row align-center">
                            <div v-text="callDto['telephonistName']" class="mr-2"></div>
                            <v-icon small color="" style="margin-right: 2px">mdi-calendar</v-icon>
                            <div class="mr-2">{{ callDto["salesCall"].created | formatDate }}</div>
                            <v-icon small color="" style="margin-right: 2px">mdi-clock-outline</v-icon>
                            <div>{{ callDto["salesCall"].created | formatTime }}</div>
                          </div>
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-text="callDto['salesCall']['notes']"
                                              class="mt-1"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-divider v-if="index < salesCalls.length - 1"
                             :key="'divider' + callDto['salesCall'].id"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>

        <div class="d-flex flex-column flex-grow-1 ml-8">
          <div class="d-flex flex-wrap align-center" style="min-height: 64px">
            <div v-if="!ongoingCall" class="d-flex align-center">
              <div class="text-h5 mr-4 mb-2">Gespräch:</div>
              <v-btn v-for="callType in startCallButtons" :key="'startCall' + callType" class="mr-2 mb-2"
                     color="primary" :disabled="networkRequestInProgress" style="text-transform: none"
                     @click="startCall(callType)">
                <v-icon left class="fix-button-icon-size">{{ getCallTypeIcon(callType) }}</v-icon>
                {{ getCallTypeString(callType) }}
              </v-btn>
            </div>

            <v-btn v-if="ongoingCall" class="mr-2 mb-2" color="primary" :disabled="networkRequestInProgress"
                   style="text-transform: none" @click="endCallButtonPressed(0)">
              <v-icon left class="fix-button-icon-size">mdi-phone-hangup</v-icon>
              Abbrechen
            </v-btn>

            <div v-if="ongoingCall" class="d-flex">
              <v-btn v-for="outcome in buttonsForCallType[ongoingCall.type]" :key="'outcomeButton' + outcome"
                     class="mr-2 mb-2" :disabled="networkRequestInProgress" style="text-transform: none"
                     :color="possibleCallOutcomes[outcome].color" dark @click="endCallButtonPressed(outcome)">
                <v-icon left class="fix-button-icon-size">{{ possibleCallOutcomes[outcome].icon }}</v-icon>
                {{ possibleCallOutcomes[outcome].label }}
              </v-btn>
            </div>
          </div>

          <div v-if="selectedCallIndex !== undefined" class="mb-6">
            <div class="mb-1">Notizen:</div>
            <div v-for="(callDto, index) in salesCalls" :key="'salesCall' + callDto['salesCall'].id">
              <v-textarea v-if="index === selectedCallIndex" v-model="callDto['salesCall'].notes" outlined
                          :readonly="index > 0 || callDto['salesCall'].outcome > 0" hide-details
                          style="max-width: 686px;"></v-textarea>
            </div>
          </div>

          <div class="d-flex flex-wrap">
            <div class="text-h5 mr-4 mb-2" style2="width: 90px">E-Mail:</div>
            <v-btn v-for="(value, key) in emailTemplateNames" :key="'etn' + key" class="mr-2 mb-2"
                   color="primary" :disabled="networkRequestInProgress" style="text-transform: none"
                   @click="emailButtonClicked(key)">
              <v-icon left class="fix-button-icon-size">mdi-email</v-icon>
              <span v-text="value ? value : '(unbenannt)'"></span>
            </v-btn>
          </div>


        </div>
      </div>

    </v-container>

    <FollowUpDialog></FollowUpDialog>
    <SendEmailDialog></SendEmailDialog>
  </v-card>
</template>

<script>
import mitt from "mitt";
import FollowUpDialog from "@/components/FollowUpDialog";
import SendEmailDialog from "@/components/SendEmailDialog";

window.mitt = window.mitt || new mitt();

export default {
  name: "MemberDetails",
  components: { SendEmailDialog, FollowUpDialog },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    salesCalls: [],
    emailTemplateNames: {},
    selectedCallIndex: undefined,
    networkRequestInProgress: false,
    ongoingCall: null,
    // final fields
    startCallButtons: [1, 2, 3, 4, 6, 5],
    buttonsForCallType: {
      1: [1, 2, 3, 5, 8],
      2: [4, 3, 5, 6, 7],
      3: [4, 3, 5, 8],
      4: [4, 3, 5, 8],
      5: [4, 3, 5, 8]
    },
    possibleCallOutcomes: [
      { outcome: 0, label: "Abgebrochen" },
      { outcome: 1, label: "Keine Antwort", color: "secondary", icon: "mdi-phone-missed" },
      { outcome: 2, label: "Ungültige Nummer", color: "red", icon: "mdi-help" },
      { outcome: 3, label: "Kein Interesse", color: "red", icon: "mdi-phone-remove" },
      { outcome: 4, label: "Nicht erschienen", color: "secondary", icon: "mdi-phone-missed" },
      { outcome: 5, label: "Auf Wiedervorlage", color: "orange darken-1", icon: "mdi-clock-outline" },
      { outcome: 6, label: "Nicht qualifiziert", color: "red", icon: "mdi-thumb-down" },
      { outcome: 7, label: "Qualifiziert", color: "green", icon: "mdi-thumb-up" },
      { outcome: 8, label: "Erfolg", color: "green", icon: "mdi-phone-check" }
    ]
  }),
  watch: {
    userId() {
      this.userIdChanged();
    }
  },
  filters: {
    formatDateShort: function(value) {
      return new Date(value).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    },
    formatDate: function(value) {
      return new Date(value).toLocaleDateString("de-de", {
        weekday: "long", year: "numeric", month: "short", day: "numeric"
      });
    },
    formatTime: function(value) {
      return new Date(value).toLocaleTimeString("de-de").substring(0, 5) + " Uhr";
    }
  },
  mounted() {
    this.userIdChanged();
  },
  methods: {
    userIdChanged() {
      if (this.userId > 0) {
        this.ongoingCall = null;
        this.loadMemberDetails(this.userId);
      }
    },
    closeDialog() {
      this.ongoingCall = null;
      this.salesCalls = [];
      this.selectedCallIndex = undefined;
      window.mitt.emit("closeMemberDetails");
    },
    loadMemberDetails(userId) {
      this.$store.dispatch("getRequest", ["members/" + userId, this.loadMemberDetailsCallback]);
    },
    loadMemberDetailsCallback(data) {
      this.salesCalls = data["salesCalls"];
      this.emailTemplateNames = data["emailTemplateNames"];
    },
    startCall(type) {
      this.networkRequestInProgress = true;
      let payload = {
        type: type,
        userId: this.userId
      };
      this.$store.dispatch("postRequest", ["sales-calls", payload, this.createCallCallback]);
    },
    createCallCallback(data) {
      this.networkRequestInProgress = false;
      this.ongoingCall = data[0]["salesCall"];

      this.salesCalls = [];
      this.$nextTick(() => {
        this.salesCalls = data;
      });
      setTimeout(function() {
        this.selectedCallIndex = 0;
      }.bind(this), 200);
    },
    endCall() {
      this.ongoingCall = null;
    },
    endCallButtonPressed(outcome) {
      if (outcome === 5) {
        window.mitt.emit("showFollowUpDialog", this.followUpDialogCallback);
      } else {
        this.endCallWithOutcome(outcome);
      }
    },
    followUpDialogCallback(followUpDate) {
      this.endCallWithOutcome(5, followUpDate);
    },
    endCallWithOutcome(outcome, followUpDate) {
      this.ongoingCall.outcome = outcome;
      this.ongoingCall.followUpDate = followUpDate;
      let data = {
        outcome: outcome,
        followUpDate: followUpDate,
        notes: this.ongoingCall.notes
      };
      this.networkRequestInProgress = true;
      this.$store.dispatch("putRequest", ["sales-calls/" + this.ongoingCall.id, data, this.endCallCallback]);
    },
    endCallCallback() {
      this.networkRequestInProgress = false;
      this.ongoingCall = null;
    },
    emailButtonClicked(templateId) {
      this.networkRequestInProgress = true;
      this.$store.dispatch("getRequest", ["email-templates/populate-for-member/" + templateId + "/" + this.userId, this.populateTemplateCallback]);
    },
    populateTemplateCallback(data) {
      this.networkRequestInProgress = false;
      window.mitt.emit("showSendEmailDialog", data);
    },
    getCallTypeString(type) {
      switch (type) {
        case 1:
          return "Erstkontakt";
        case 2:
          return "Qualifizierung";
        case 3:
          return "Verkauf";
        case 4:
          return "Zielgespräch";
        case 5:
          return "Sonstiges";
        case 6:
          return "Pers. Test";
      }
      return "unknown call type: " + type;
    },
    getCallTypeIcon(type) {
      switch (type) {
        case 1:
          return "mdi-phone";
        case 2:
          return "mdi-message-video";
        case 3:
          return "mdi-currency-usd";
        case 4:
          return "mdi-bullseye-arrow";
        case 5:
          return "mdi-phone";
        case 6:
          return "mdi-account-check";
      }
      return "";
    }
  }
};
</script>

<style scoped>

</style>