<template>
  <v-dialog v-model="dialogOpen" max-width="400" persistent>
    <v-card>
      <v-toolbar dark color="grey darken-4">
        <v-spacer></v-spacer>
        <v-toolbar-title>Auf Wiedervorlage</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container class="d-flex flex-column align-center py-4" fluid>
        <div class="text-overline grey--text text--darken-2 mb-2 text-center">Zeitraum wählen</div>

        <v-btn-toggle dense v-model="periodValue" @change="periodInfoChanged">
          <v-btn v-for="index in 12" :key="index" style="width: 27.158px; min-width: 0">{{ index }}</v-btn>
        </v-btn-toggle>

        <div class="d-flex align-center mt-2">
          <v-btn-toggle v-model="periodUnit" @change="periodInfoChanged">
            <v-btn>Tage</v-btn>
            <v-btn>Wochen</v-btn>
            <v-btn>Monate</v-btn>
            <v-btn>Jahre</v-btn>
          </v-btn-toggle>
        </div>

        <div class="text-overline mt-4 mb-2 grey--text text--darken-2 text-center">oder Datum wählen</div>

        <div class="d-flex justify-center">
          <v-date-picker elevation="2" v-model="pickedDate" color="blue darken-3" locale="de-de"
                         @click:date="dateSelected"></v-date-picker>
        </div>

        <div class="mt-8 mb-2 d-flex justify-center">
          <v-btn dark color="secondary" outlined class="mr-3" @click="closeDialog">Abbrechen</v-btn>
          <v-btn color="primary" :disabled="!hasAnythingBeenModified" @click="saveAndExit">Speichern</v-btn>
        </div>

      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from "mitt";

window.mitt = window.mitt || new mitt();

export default {
  name: "FollowUpDialog",
  data() {
    return {
      dialogOpen: false,
      periodValue: undefined,
      periodUnit: undefined,
      pickedDate: undefined,
      callback: undefined,
      // static fields
      unitMultipliers: [1, 7, 30, 365]
    };
  },
  computed: {
    hasAnythingBeenModified() {
      return !!(this.periodValue || this.periodUnit || this.pickedDate);
    }
  },
  mounted() {
    window.mitt.on("showFollowUpDialog", callback => {
      this.callback = callback
      this.dialogOpen = true
      this.periodValue = undefined
      this.periodUnit = undefined
      this.pickedDate = undefined
    });
  },
  methods: {
    periodInfoChanged() {
      if (this.periodValue === undefined) {
        this.periodValue = 0
      }

      if (this.periodUnit !== undefined) {
        let plusDays = (this.periodValue + 1) * this.unitMultipliers[this.periodUnit];
        let date = new Date();
        date.setDate(date.getDate() + plusDays);
        this.pickedDate = date.toISOString().substr(0, 10);
      }
    },
    dateSelected() {
      this.periodValue = undefined
      this.periodUnit = undefined
    },
    closeDialog() {
      this.dialogOpen = false;
    },
    saveAndExit() {
      this.callback(this.pickedDate);
      this.dialogOpen = false;
    }
  }
};
</script>

<style scoped>

</style>